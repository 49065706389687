.heroes-page-list {
  width: 70%;
  margin: 4rem auto;
  font-size: 3rem;

  .home-icon {
    position: absolute;
    top: 3rem;
    left: 3rem;
    font-size: 4rem;
    width: 8rem;
    height: 8rem;
    cursor: pointer;
  }

  .table-header-row {
    text-align: center;
  }

  .action-btn {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}