.heroes-page {
    padding: 2rem;
    width: 70%;
    margin: 0 auto;
    display: flex;


  .heroes-page-left-side {
    display: flex;
    flex-direction: column;

    .book {
      cursor: pointer;
    }
  }
  .heroes-page-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 1rem;
    padding-left: 4rem;

    .hero-wrapper {
      position: relative;
      height: 16rem;
      width: 12rem;
    }
    .hero {
      width: inherit;
      height: inherit;
    }
    .hero-overlay {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-color: #fff;
      z-index: 10;
      cursor: pointer;
      opacity: 0.5;
      &:hover {
        opacity: 0;
      }
    }
  }

  &.is-pc {
    .heroes-page-list {
      
      .spinner-border-sm {
        --bs-spinner-width: 20rem;
        --bs-spinner-height: 20rem;
        --bs-spinner-border-width: 0.8em;
        opacity: 0.6;
        margin: auto;
      }
    }
  }

  &.is-phone {
    flex-direction: column;
    height: 100%;
    width: 75%;

    .heroes-page-list {
      padding: 0;
      margin: 0;
      justify-content: center;
      width: 100%;

      .spinner-border-sm {
        margin-top: 5rem;
        --bs-spinner-width: 20rem;
        --bs-spinner-height: 20rem;
        --bs-spinner-border-width: 1.2em;
        opacity: 0.8;
      }

      .hero-wrapper {
        height: 24rem;
        width: 18rem;
      }
    }
  }
}