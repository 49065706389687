.main-page {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;

  &.is-phone {
    width: 100%;
    margin: auto;
    overflow: auto;
    .header {
      .book {
        height: 70rem;
      }
    }
    .description {
      margin-top: 18rem;
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.header-img {
  height: 16rem;
}

.header-img.book {
  height: 56rem;
  cursor: pointer;
}

.description {
  color: white;
  font-size: 8rem;
  text-align: center;
  padding: 1rem;
  margin: auto;
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
  width: 100%;
}