.hero-page {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 2rem;
  min-height: 100vh;

  .hero-page-top {
    height: 75%;
    display: flex;
    .hero-page-top-left {
      width: 35%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .hero-page-top-photo {
        height: 85rem;
        filter: drop-shadow(0 0 0.75rem rgb(97, 106, 231));
      }
      .hero-page-top-rank {
        width: 100%;
        text-align: center;
        font-size: 3.5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
      .hero-page-top-pip {
        font-size: 5rem;
        text-align: center;
        width: 80%;
      }
      .hero-page-top-title {
        font-size: 5rem;
        font-weight: 900;
        color: #786914;
        text-shadow: 1px 2px 15px #FFD700;
        letter-spacing: 2rem;
      }
    }
    .hero-page-top-rank, .hero-page-top-pip {
      letter-spacing: 8px;
      color: #123aa5;
      line-height:1em;
      font-weight: 900;
      text-shadow: 0 0 10px #FFFFFF, 2px 2px 2px rgba(132,155,206,0);
  
    }
    .hero-page-top-right {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .hero-page-top-position {
        font-size: 4rem;
        width: 80%;
        text-align: center;
        color: #CD0038;
        font-weight: 900;
        line-height: 5rem;
        font-family: sans-serif;
        text-align: center;
      }
      .hero-page-top-description {
        font-size: 4rem;
        text-align: center;
        color: #000;
        font-weight: 700;
        line-height: 5rem;
        font-family: sans-serif;
        padding-left: 2rem;

        .description-p {
          text-align: justify;
          text-indent: 8rem;
          margin: 0;
        }
      }
    }
  }
  .hero-page-bottom {
    height: 25%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    margin-top: 4rem;
  
    .brigada {
      position: absolute;
      height: 15rem;
      cursor: pointer;
    }

    .hero-page-bottom-reward {
      font-size: 4rem;
      font-style: italic;
      width: 80%;
      margin: 0 auto;
      text-align: center;
      color: #0600BD;
      font-weight: 600;
      text-shadow: #FC0 1px 0 10px;
      line-height: normal;

      .reward-p {
        margin-bottom: 4rem;
      }
    }
  }

  &.is-phone {
    height: 100%;
    overflow: auto;
    .hero-page-top {
      flex-direction: column;
      align-items: center;

      .hero-page-top-left {
        position: relative;
        width: 100%;

        .hero-page-top-pip {
          margin-bottom: 2rem;
          z-index: 10;
        }

        .hero-page-top-rank {
          margin-top: 2rem;
          z-index: 10;
        }
      }
      .hero-page-top-right {
        .hero-page-top-position {
          margin-bottom: 2rem;
        }
        .hero-page-top-description {
          margin-top: 1rem;
          text-align: justify;
          padding-left: 0;
        }
      }
    }
    .hero-page-bottom-reward {
      font-size: 4.5rem;
    }
  }    
}
