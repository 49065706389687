@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

html {
  font-size: 10px;
  user-select: none;

  @media only screen and (max-width: 1920px) {
    font-size: 7px;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 6px;
  }
  @media only screen and (max-width: 1000px) {
    font-size: 4px;
  }

  body {
    margin: 0;
    color: white;
    background-color: black;
    font-family: "Playfair Display", serif;
    font-optical-sizing: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .great-vibes-regular {
    font-family: "Great Vibes", cursive;
    font-weight: 400;
    font-style: normal;
  }
}
