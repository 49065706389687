.hero-create-edit-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .home-icon {
    position: absolute;
    top: 3rem;
    left: 3rem;
    font-size: 4rem;
    width: 8rem;
    height: 8rem;
    cursor: pointer;
  }

  .hero-create-form {
    width: 60%;
    margin: 10vh auto;
    .form {
      font-size: 3rem;
      .form-select,.form-control {
        font-size: 3rem;
      }
      .add-button {
        width: 25%;
        height: 6rem;
        font-size: 3rem;
        margin-right: 3rem;
      }
    }
  }
}