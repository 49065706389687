.admin-page {
  height: 100vh;
  width: 100%;
  position: relative;
  .admin-form {
    position: absolute;
    width: 40%;
    height: 30%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4rem;
    -webkit-box-shadow: -2px 1px 42px 19px rgba(247,247,247,1);
    -moz-box-shadow: -2px 1px 42px 19px rgba(247,247,247,1);
    box-shadow: -2px 1px 42px 19px rgba(247,247,247,1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    form {
      width: 75%;
      .form-label {
        font-size: 1.4rem;
      }
      .form-control {
        font-size: 2rem;
      }
    }
  }

  .admin-page-btn {
    width: 16rem;
    height: 4rem;
    font-size: 1.6rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
}